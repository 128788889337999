[data-locale='ar'] * {
  line-height: 1.8 !important;
}

[data-locale='uig'] * {
  line-height: 1.6 !important;
}

html[lang="ur"] {
  font-size: 22px;
}
